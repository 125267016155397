import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import EventsHeader from "../components/EventsHeader";
import Footer from "../components/Footer";
import NewsArchive2023 from "../components/newsarchives2023";
// import NewsArchive from '../components/newsarchive';

const NewsArchives2023 = () => {
    return (
        <Layout pageTitle="2023 NEWS & Events | LUTH">
            <NavOne />
            <EventsHeader title="" />
            <NewsArchive2023 />
            <Footer />
        </Layout>
    );
};

export default NewsArchives2023;