import { Link } from 'gatsby';
import React from 'react';
// import blogTwo1 from '../assets/images/2022/medical-outreach/fake-news01.jpg';
// import blogTwo2 from '../assets/images/2022/nass/10.jpg';
// import blogTwo3 from '../assets/images/2022/medical-outreach/8.jpg';
// import blogTwo4 from '../assets/images/2022/nurse-ndidi-retire/5.jpg';
// import blogTwo5 from '../assets/images/2022/federal-seminar/9.jpg';
import blogTwo6 from '../assets/images/2023/nlng-upgrades-bed/1.jpeg';
// import blogTwo7 from '../assets/images/2022/nursing-director-retire/001.jpg';
// import b4 from '../assets/images/oandg.jpg';

const NewsArchives2023 = () => {
  return (
    <section className='faq-one'>
      <div className='container'>
        <div className='row no-gutters'>
        
        
        <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2023/hon-comm-visit-to-luth'>
                  NLNG upgrades 120-bed Obstetrics and Gynaecology block at LUTH 
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
        {/* <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/first-bank-donates-to-luth'>
                    First Bank of Nigeria donates to LUTH Paediatrics Patients
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
        <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/commission-outpatient-centre'>
                  Commissioning of the New Ultra Modern Outpatient Centre
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
        <div className='col-lg-6'>
            <div className='faq-one__single'>          
              <div className='faq-one__content'>
              <img src={blogTwo2} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/news/nass'>
                  NASS scores LUTH high in capital projects execution
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo1} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/news/disclaimer'>
                  Disclaimer: Fake News
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>             
              <div className='faq-one__content'>
              <img src={blogTwo7} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/news/nursing-director-retire'>
                  The NEST360; (FCC) viz Bill and Melinda Gates, and (CIFF) courtesy visit to the Chief Medical Director of LUTH
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo3} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/news/comm-health-medical-outreach'>
                  Medical Outreach to Mashewele Community
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo4} height={370} />
                <h2 className='faq-one__title'> 
                  <Link to='/news/nurse-ndidi-retire'>
                  Nurse Ndidi Enuwa Retires
                  </Link>
                </h2>              
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo5} height={370} /> 
                <h2 className='faq-one__title'>
                  <Link to='/news/federal-seminar'>
                  Federal Ministry of Health Held Seminar in LUTH
                  </Link>
                </h2>               
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2017'>
                  Federal House of Representatives Health Committee Commends LUTH
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/blood-donor-clinic'>
                  Blood Donor's Clinic
                  </Link>
                </h2>                
              </div>
            </div>
          </div> 
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/luth-mgmt-board-nsia'>
                    LUTH Management Board Visits NSIA 
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/mabo-foundation-visit'>
                  Nigeria In Diaspora Commission (NIDCOM), MABO Foundation and
                  NAPAC-USA visited LUTHA
                  </Link>
                </h2>                
              </div>
            </div>
          </div>  
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/wgo-training'>
                  World Gastroenterology Organization Lagos training centre workshop
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
              <img src={blogTwo6} height={370} />
                <h2 className='faq-one__title'>
                  <Link to='/events/2022/world-oral-health-day'>
                  World Oral Health Day
                  </Link>
                </h2>                
              </div>
            </div>
          </div> */}


        </div>
      </div>
    </section>
  );
};

export default NewsArchives2023;